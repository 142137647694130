var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "boxed-layout justify-content-center" }, [
    _c("div", [
      _vm.userAccount
        ? _c(
            "form",
            {
              attrs: { name: "editForm", role: "form", novalidate: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save()
                },
              },
            },
            [
              _c("h2", { attrs: { id: "myUserLabel" } }, [
                _vm._v("Create or edit a User"),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    attrs: { hidden: !_vm.userAccount.id },
                  },
                  [
                    _c("label", [_vm._v("ID")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userAccount.id,
                          expression: "userAccount.id",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", name: "id", readonly: "" },
                      domProps: { value: _vm.userAccount.id },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.userAccount, "id", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Login"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.userAccount.login.$model,
                        expression: "$v.userAccount.login.$model",
                      },
                    ],
                    staticClass: "form-control",
                    class: {
                      valid: !_vm.$v.userAccount.login.$invalid,
                      invalid: _vm.$v.userAccount.login.$invalid,
                    },
                    attrs: { type: "text", name: "login" },
                    domProps: { value: _vm.$v.userAccount.login.$model },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.$v.userAccount.login,
                          "$model",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.userAccount.login.$anyDirty &&
                  _vm.$v.userAccount.login.$invalid
                    ? _c("div", [
                        !_vm.$v.userAccount.login.required
                          ? _c(
                              "small",
                              { staticClass: "form-text text-danger" },
                              [
                                _vm._v(
                                  "\n              Pflichtfeld.\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.userAccount.login.maxLength
                          ? _c(
                              "small",
                              { staticClass: "form-text text-danger" },
                              [
                                _vm._v(
                                  "\n              This field cannot be longer than 50 characters.\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.userAccount.login.pattern
                          ? _c(
                              "small",
                              { staticClass: "form-text text-danger" },
                              [
                                _vm._v(
                                  "\n              This field can only contain letters, digits and e-mail addresses.\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-control-label",
                      attrs: { for: "firstName" },
                    },
                    [_vm._v("First Name")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.userAccount.firstName.$model,
                        expression: "$v.userAccount.firstName.$model",
                      },
                    ],
                    staticClass: "form-control",
                    class: {
                      valid: !_vm.$v.userAccount.firstName.$invalid,
                      invalid: _vm.$v.userAccount.firstName.$invalid,
                    },
                    attrs: { type: "text", id: "firstName", name: "firstName" },
                    domProps: { value: _vm.$v.userAccount.firstName.$model },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.$v.userAccount.firstName,
                          "$model",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.userAccount.firstName.$anyDirty &&
                  _vm.$v.userAccount.firstName.$invalid
                    ? _c("div", [
                        !_vm.$v.userAccount.firstName.maxLength
                          ? _c(
                              "small",
                              { staticClass: "form-text text-danger" },
                              [
                                _vm._v(
                                  "\n              This field cannot be longer than 50 characters.\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-control-label",
                      attrs: { for: "lastName" },
                    },
                    [_vm._v("Last Name")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.userAccount.lastName.$model,
                        expression: "$v.userAccount.lastName.$model",
                      },
                    ],
                    staticClass: "form-control",
                    class: {
                      valid: !_vm.$v.userAccount.lastName.$invalid,
                      invalid: _vm.$v.userAccount.lastName.$invalid,
                    },
                    attrs: { type: "text", id: "lastName", name: "lastName" },
                    domProps: { value: _vm.$v.userAccount.lastName.$model },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.$v.userAccount.lastName,
                          "$model",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.userAccount.lastName.$anyDirty &&
                  _vm.$v.userAccount.lastName.$invalid
                    ? _c("div", [
                        !_vm.$v.userAccount.lastName.maxLength
                          ? _c(
                              "small",
                              { staticClass: "form-text text-danger" },
                              [
                                _vm._v(
                                  "\n              This field cannot be longer than 50 characters.\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-control-label",
                      attrs: { for: "email" },
                    },
                    [_vm._v("Email")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.userAccount.email.$model,
                        expression: "$v.userAccount.email.$model",
                      },
                    ],
                    staticClass: "form-control",
                    class: {
                      valid: !_vm.$v.userAccount.email.$invalid,
                      invalid: _vm.$v.userAccount.email.$invalid,
                    },
                    attrs: {
                      type: "email",
                      id: "email",
                      name: "email",
                      email: "",
                      required: "",
                    },
                    domProps: { value: _vm.$v.userAccount.email.$model },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.$v.userAccount.email,
                          "$model",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.userAccount.email.$anyDirty &&
                  _vm.$v.userAccount.email.$invalid
                    ? _c("div", [
                        !_vm.$v.userAccount.email.required
                          ? _c(
                              "small",
                              { staticClass: "form-text text-danger" },
                              [
                                _vm._v(
                                  "\n              Your email is required.\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.userAccount.email.email
                          ? _c(
                              "small",
                              { staticClass: "form-text text-danger" },
                              [
                                _vm._v(
                                  "\n              Your email is invalid.\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.userAccount.email.minLength
                          ? _c(
                              "small",
                              { staticClass: "form-text text-danger" },
                              [
                                _vm._v(
                                  "\n              Your email is required to be at least 5 characters.\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.userAccount.email.maxLength
                          ? _c(
                              "small",
                              { staticClass: "form-text text-danger" },
                              [
                                _vm._v(
                                  "\n              Your email cannot be longer than 50 characters.\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-check" }, [
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "activated" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userAccount.activated,
                            expression: "userAccount.activated",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          disabled: _vm.userAccount.id === null,
                          type: "checkbox",
                          id: "activated",
                          name: "activated",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.userAccount.activated)
                            ? _vm._i(_vm.userAccount.activated, null) > -1
                            : _vm.userAccount.activated,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.userAccount.activated,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.userAccount,
                                    "activated",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.userAccount,
                                    "activated",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.userAccount, "activated", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Activated")]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Profiles")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userAccount.authorities,
                          expression: "userAccount.authorities",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { multiple: "", name: "authority" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.userAccount,
                            "authorities",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.authorities, function (authority) {
                      return _c(
                        "option",
                        { key: authority, domProps: { value: authority } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(authority) +
                              "\n            "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.previousState()
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "ban" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Cancel")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      type: "submit",
                      disabled: _vm.$v.userAccount.$invalid || _vm.isSaving,
                    },
                  },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "save" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Save")]),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }